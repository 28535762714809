import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography,
  Box,
  Paper,
  Button
} from '@mui/material';
import { LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Leaf, ArrowRight, Lightbulb, UtensilsCrossed, Flower, Tractor} from 'lucide-react';
import ReportTable from './ReportTable';
import TopRecipes from './TopRecipes';
// Données de test

// Exemple de conseil détaillé
const tip = {
    id: 1,
    title: "Privilégiez les produits locaux et de saison",
    description: "Consommer local et de saison permet de réduire l'empreinte carbone liée au transport et aux serres chauffées. C'est aussi une excellente façon de soutenir l'économie locale et de redécouvrir le véritable goût des aliments.",
    action: "Voir le calendrier des saisons",
    impact: "Réduction de 30% des émissions de CO2"
  };

const carbonData = [
  { month: 'Jan', impact: 2.8 },
  { month: 'Fév', impact: 2.6 },
  { month: 'Mar', impact: 2.4 },
  { month: 'Avr', impact: 2.3 },
  { month: 'Mai', impact: 2.1 },
  { month: 'Juin', impact: 2.0 },
  { month: 'Juil', impact: 1.9 },
  { month: 'Août', impact: 1.8 },
  { month: 'Sep', impact: 1.7 },
  { month: 'Oct', impact: 1.6 },
  { month: 'Nov', impact: 1.5 },
  { month: 'Déc', impact: 1.4 },
];

const labelData = [
  { name: 'Bio', value: 35, color: '#4CAF50' },
  { name: 'Label Rouge', value: 25, color: '#F44336' },
  { name: 'AOP/AOC', value: 15, color: '#2196F3' },
  { name: 'IGP', value: 10, color: '#FF9800' },
  { name: 'HVE', value: 8, color: '#9C27B0' },
  { name: 'Autres', value: 7, color: '#607D8B' }
];

const ecoscoreData = [
    { name: 'Ecoscore A', value: 10, color: '#1E8F4E' },
    { name: 'Ecoscore B', value: 30, color: '#2ECC71' },
    { name: 'Ecoscore C', value: 20, color: '#F5C100' },
    { name: 'Ecoscore D', value: 35, color: '#EF7E1A' },
    { name: 'Ecoscore E', value: 5, color: '#D93726' },
  ];

  const nutriscoreData = [
    { name: 'Nutriscore A', value: 10, color: '#1E8F4E' },
    { name: 'Nutriscore B', value: 30, color: '#2ECC71' },
    { name: 'Nutriscore C', value: 20, color: '#F5C100' },
    { name: 'Nutriscore D', value: 35, color: '#EF7E1A' },
    { name: 'Nutriscore E', value: 5, color: '#D93726' },
  ];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={3} sx={{ padding: 2, backgroundColor: 'white' }}>
        <Typography variant="body2" color="textPrimary">{label}</Typography>
        {payload.map((entry, index) => (
          <Typography 
            key={index} 
            variant="body2" 
            sx={{ color: entry.color || entry.payload.color }}
          >
            {`${entry.name}: ${entry.value}${entry.name === 'impact' ? ' kg CO₂e' : '%'}`}
          </Typography>
        ))}
      </Paper>
    );
  }
  return null;
};

const StatCard = ({ title, value, change, icon: Icon, unit = '', imageUrl }) => (
    <Card
      elevation={2}
      sx={{
        height: '100%',
        transition: 'all 0.3s',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 6
        }
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
          <Icon size={20} color="#059669" />
        </Box>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', // Centre l'image
            alignItems: 'center',
            minHeight: '60px' // Assure une hauteur minimale consistante
          }}
        >
          {imageUrl ? (
            <Box
              component="img"
              src={imageUrl}
              alt={title}
              sx={{
                height: '40px',
                width: 'auto',
                objectFit: 'contain',
                borderRadius: 1
              }}
            />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
            <Typography variant="h4" component="div">
              {value}{unit}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: change >= 0 ? 'success.main' : 'error.main',
                fontWeight: 500
              }}
            >
              {change >= 0 ? '+' : ''}{change}%
            </Typography>
          </Box>
            
          )}
        </Box>
      </CardContent>
    </Card>
  );

const RestaurantDashboard = () => {
     //Get current month in french
  const date = new Date();
  const options = { month: 'long' };
  let month = new Intl.DateTimeFormat('fr-FR', options).format(date);
  //Upper case first char
  month = month.charAt(0).toUpperCase() + month.slice(1);
  //Get current year
  const year = date.getFullYear();

  const handleClick = () => {
    console.log('Clicked for more details');
    // Gérer l'action ici
  };

  return (
    <Box sx={{ p: 3, bgcolor: '#f5f5f5', minHeight: '100vh',marginLeft: "15%"  }}>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }} >
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10}}>Tableau de bord - {month} {year}</h1>
      </Box>
      
      <Box 
        sx={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 3,
          autoRows: 'minmax(100px, auto)'
        }}
      >
    

        <StatCard
        title="Label FoodPrint"
        imageUrl="ecoscore/A.svg"
        change={5}
        icon={UtensilsCrossed}
        />
        <StatCard 
          title="Impact Carbone Moyen" 
          value="1.4" 
          unit=" kg CO₂e"
          change={-12.5} 
          icon={Leaf}
        />
        <StatCard 
          title="Part de local" 
          value="10" 
          unit="%" 
          change={0.8} 
          icon={Tractor}
        />
        <StatCard 
          title="Part du Bio" 
          value="35" 
          unit="%" 
          change={7.1} 
          icon={Flower}
        />
    
    <Card
      elevation={2}
      sx={{
        maxWidth: 400,
        gridColumn: 'span 1',
        gridRow: 'span 2',
        borderRadius: 3,
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
        },
      }}
    >
      <CardContent sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2,
        pb: '16px !important' // Supprime le padding par défaut du CardContent
      }}>
        {/* Icône et titre */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box 
            sx={{ 
              backgroundColor: 'primary.light',
              borderRadius: '50%',
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Lightbulb size={24} color="#1976d2" />
          </Box>
          <Typography 
            variant="h6" 
            component="h2"
            color="primary.main"
            fontWeight="bold"
          >
            Conseil du jour
          </Typography>
        </Box>

        {/* Contenu principal */}
        <Typography variant="h5" component="h3">
          {tip.title}
        </Typography>

        <Typography variant="body1" color="text.secondary">
          {tip.description}
        </Typography>

        {/* Impact */}
        <Box 
          sx={{ 
            backgroundColor: 'success.light',
            borderRadius: 2,
            p: 2
          }}
        >
          <Typography variant="body2" color="success.dark">
            {tip.impact}
          </Typography>
        </Box>

        {/* Bouton */}
        <Button
          variant="contained"
          fullWidth
          endIcon={<ArrowRight />}
          onClick={handleClick}
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            py: 1.5
          }}
        >
          {tip.action}
        </Button>
      </CardContent>
    </Card>

        <Card 
          elevation={2}
          sx={{ 
            gridColumn: 'span 2',
            gridRow: 'span 1',
            height: '300px' // Augmentation de la hauteur
          }}
        >
          <CardContent 
            sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              padding: '16px !important' // Supprime le padding par défaut du dernier élément
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Impact Carbone par Repas
            </Typography>
            <Box sx={{ flex: 1, minHeight: 0, position: 'relative' }}>
              <ResponsiveContainer width="100%" height="90%">
                <LineChart 
                  data={carbonData} 
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis unit=" kg CO₂e" />
                  <Tooltip content={<CustomTooltip />} />
                  <Line 
                    type="monotone" 
                    dataKey="impact" 
                    stroke="#059669" 
                    strokeWidth={2}
                    dot={{ fill: '#059669', strokeWidth: 2 }}
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>

        <Card
            elevation={2}
            sx={{
                gridColumn: 'span 1',
                gridRow: 'span 1',
                height: '300px'
            }}
            >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                Labels
                </Typography>
                <Box sx={{ flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart margin={{ left: 10, right: 10 }}> {/* Ajout des marges */}
                    <Pie
                        data={labelData}
                        cx="40%" // Ajusté de 35% à 40%
                        cy="50%"
                        innerRadius={40}
                        outerRadius={60}
                        paddingAngle={2}
                        dataKey="value"
                    >
                        {labelData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                        iconType="circle"
                        wrapperStyle={{ 
                        paddingLeft: '10px',
                        fontSize: '12px'
                        }}
                    />
                    </PieChart>
                </ResponsiveContainer>
                </Box>
            </CardContent>
        </Card>

        <Card
            elevation={2}
            sx={{
                gridColumn: 'span 1',
                gridRow: 'span 1',
                height: '300px'
            }}
            >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                Ecoscore de vos recettes
                </Typography>
                <Box sx={{ flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart margin={{ left: 10, right: 10 }}> {/* Ajout des marges */}
                    <Pie
                        data={ecoscoreData}
                        cx="40%" // Ajusté de 35% à 40%
                        cy="50%"
                        innerRadius={40}
                        outerRadius={60}
                        paddingAngle={2}
                        dataKey="value"
                    >
                        {ecoscoreData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        layout="vertical"
                        align="right"
                        verticalAlign="middle"
                        iconType="circle"
                        wrapperStyle={{ 
                        paddingLeft: '10px',
                        fontSize: '12px'
                        }}
                    />
                    </PieChart>
                </ResponsiveContainer>
                </Box>
            </CardContent>
        </Card>

        <Card
            elevation={2}
            sx={{
                gridColumn: 'span 2',
                gridRow: 'span 1',
                height: '300px'
            }}
            >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" >
                Vos recettes les plus durables
                </Typography>
                <Box sx={{ flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center' }}>
                <ResponsiveContainer width="90%" height="100%">
                    <TopRecipes/>
                </ResponsiveContainer>
                </Box>
            </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default RestaurantDashboard;